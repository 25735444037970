@import "../styles/theme.scss";
@import "../styles/viewports";

.myFarm {
  h4 {
    width: 100%;
    flex-wrap: nowrap;
    font-family: $font-family-title;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
  }
  .bold {
    font-weight: $bold;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  margin-top: 40px;
  padding: 24px 32px;
  border: 1px solid var(--outline);
  border-radius: 10px;
  background: var(--background-element);
  box-sizing: border-box;
  @include breakpoint(lg) {
    margin: 40px auto 0;
  }
  @include breakpoint(md) {
    max-width: 90%;
  }
}
