@import "../styles/theme";
@import "../styles/viewports";

div.reviewDeposit {
  margin-top: 0;
  padding: 40px;
  background-color: $white;
  color: $text-black;
  border-radius: 8px;

  h3 {
    font-size: 30px;
    font-weight: $bold;
    color: $text-black;
    margin-top: 0;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .table {
    display: flex;
    flex-direction: column;
    @include breakpoint(sm) {
      font-size: 14px;
    }

    .eachToken {
      margin-bottom: 16px;

      img {
        margin-right: 8px;
        vertical-align: text-bottom;
      }

      div {
        display: inline-block;
      }

      & > .value {
        width: 55%;
        @include breakpoint(md) {
          width: 27%;
        }
      }
      & > .token {
        width: 45%;
        img {
          height: 24px;
          width: 24px;
        }
        @include breakpoint(md) {
          width: 73%;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .depositInfoItem {
      margin-bottom: 16px;

      display: flex;
      flex-direction: row;

      & > .label {
        width: 45%;
        @include breakpoint(md) {
          width: 73%;
        }
      }

      & > .value {
        width: 55%;
        @include breakpoint(md) {
          width: 27%;
        }
      }
    }

    div.rates {
      display: grid;
      grid-template-rows: auto;
      row-gap: 8px;

      span {
        display: block;
      }
    }
  }

  div.bottom {
    background: $black;
    margin: 0 -40px -40px -40px;
    padding: 16px 40px 24px 40px;
    border-radius: 0px 0px 8px 8px;

    color: $text-white;

    p {
      font-size: 14px;
      font-style: italic;
      margin-top: 24px;
      margin-bottom: 32px;
      color: $text-white;
    }

    span {
      font-size: 14px;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .divider {
    background-color: $divider-red;
    margin: 12px 0 16px 0;
  }
}
