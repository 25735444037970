@import "../styles/theme";
.exchangeWrapper {
  width: 100%;
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    button {
      width: 45%;
      min-height: 50px;
    }
  }
  .error {
    text-align: center;
    color: $error;
  }
}
.stepWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 24px;
  h2 {
    font-size: 30px;
    font-weight: 700;
  }
}

.balanceWrapper {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-bottom: 12px;
  b.balance {
    cursor: pointer;
    color: $primary-blue;
  }
}
