@import "../styles/theme";
.timeline {
  font-size: 12px;
  margin: 24px 0;
  .timelineStep {
    &.active {
      color: $primary;
      border-color: $primary;
      svg {
        fill: $primary;
        stroke: $primary;
      }
    }
    &.inactive {
      color: var(--text-lightest);
      border-color: var(--text-lightest);
      svg {
        fill: var(--text-lightest);
        stroke: var(--text-lightest);
      }
    }
  }
  .timelineLine {
    height: 20px;
    width: 1px;
    margin: 8px 0 8px 4px;
    border-left: 1px solid;
  }
}
