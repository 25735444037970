@import "../styles/theme";

.container {
  border-radius: 8px;
  border: 1px solid var(--emphasis-2);
  background: var(--primary-background);
  padding: 16px;
  width: 176px;
  font-size: 16px;
  position: absolute;
  top: 0;
}

.divider {
  border: 1px solid var(--emphasis-2);
  height: 1px;
  width: 100%;
  margin: 8px;
}

.logo {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: white;
}

.sectionTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.sectionItem {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 4px 0;
  &::before {
    content: " ";
  }
  &.active::before {
    content: "✓";
  }
  &:hover {
    background: var(--emphasis-2);
    cursor: pointer;
  }
}
