@import "../styles/theme";

div.reviewMigration {
  margin-top: 0;
  padding: 40px;
  background-color: $white;
  color: $text-black;
  border-radius: 8px;

  h3 {
    font-family: $font-family-title;
    font-size: 30px;
    font-weight: $bold;
    color: $text-black;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .migrateTop {
    margin-bottom: 36px;

    .floatRight {
      float: right;
    }

    .row {
      margin-top: 8px;
    }
  }

  .info {
    margin-top: 24px;
  }

  .bottom {
    background: $black;
    margin: 0 -40px -40px -40px;
    padding: 24px 40px 40px 40px;
    border-radius: 0px 0px 8px 8px;

    p {
      margin: 0 0 32px 0;
      color: $text-white;
      line-height: 20px;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}
