html {
  /* prevent screen shaking due to the appearing of scrollbar */
  margin-left: calc(100vw - 100%);
  overflow-y: scroll;
}
body {
  font-family: "Source Code Pro", monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
