@import "../styles/theme";
@import "../styles/viewports";

div.poolOverview {
  margin-top: 40px;
  width: 100%;
  background: var(--background-element);
  box-shadow: var(--shadow);
  border: 1px solid var(--outline);
  border-radius: 10px;
  padding: 24px 32px;
  display: grid;
  grid-template-columns: 45% 55%;
  @include breakpoint(md) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    row-gap: 24px;
    padding: 24px 6%;
    width: 80%;
  }
  @include breakpoint(sm) {
    width: 90%;
    padding: 24px 2%;
  }
  @include breakpoint(xs) {
    width: 100%;
    padding: 24px 2%;
  }

  .left {
    display: grid;
    grid-template-rows: 40px 24px 22px 10px 20px;
  }

  .titleAndTag {
    grid-row: 1/2;
    display: flex;
  }

  h4.title {
    font-family: $font-family-title;
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 30px;
    font-weight: $bold;
    color: var(--text-title);
    margin-right: 16px;
  }

  .balance {
    grid-row: 3/4;
  }
  .tokens {
    grid-row: 5/6;
    display: flex;
    flex-direction: row;
    @include breakpoint(md) {
      width: 100%;
      justify-content: space-between;
    }
  }

  div.token {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  img.tokenIcon {
    margin-right: 4px;
    vertical-align: sub;
    height: 18px;
    width: 18px;
    background-color: var(--primary-background);
    border-radius: 50%;
  }

  div.right {
    display: grid;
    justify-content: end;
    @include breakpoint(md) {
      justify-content: space-between;
      row-gap: 24px;
      grid-template-columns: 100%;
    }

    .margin {
      margin-right: 24px;
      @include breakpoint(md) {
        margin-right: auto;
      }
    }
    .poolInfo {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-self: end;
      @include breakpoint(md) {
        justify-self: start;
        width: 100%;
        justify-content: space-between;
      }
    }
    span.label {
      display: block;
      margin-bottom: 4px;
      font-weight: $bold;
    }

    .plus {
      font-weight: $medium;
      color: var(--text-selected);
      display: block;
    }
    .minus {
      font-weight: $medium;
      color: $error;
    }

    .buttons {
      align-self: end;
      justify-self: end;
      display: grid;
      grid-template-columns: 176px 176px;
      column-gap: 16px;
      @include breakpoint(md) {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .underline {
    border-bottom: 1px dotted var(--text);
  }
}

div.outdated {
  border-color: $yellow2;
}

div.poolOverview:first-child {
  margin-top: 0;
}
