/**
* GLOBAL STYLES
* Imported once at the top level
* Used everywhere
*/
@import "fonts";
@import "theme";

* {
  box-sizing: border-box;
  margin: 0px;
  font-family: "VT323", monospace, sans-serif;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
p,
b,
span {
  font-family: $font-family;
  line-height: 20px;
  size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
}
input {
  border: none;
  font-size: 100%;
}

svg,
img {
  // TODO: get rid of this once chakra is more integrated
  display: inline !important;
}

ul {
  list-style: none;
}

:root {
  --primary-foreground: #000;
  --primary-background: #fff;
  --background: #ffffff;
  --background-lightgrey: #dfdde3;
  --background-element: rgba(255, 255, 255, 0.7);
  --background-element-hover: rgba(255, 255, 255, 0.88);
  --background-main: #ffffff url("../assets/bg_light.svg") center/auto 60%
    no-repeat fixed;
  --background-logo: #{$primary};
  --secondary-button: #{$light-blue};
  --emphasis-1: #{$sand2}; // passive state
  --emphasis-2: #{$sand1}; // active state
  --primary-accent: #{$primary-blue};
  --outline: #000;
  --outline-gray: #{$black30};
  --option-hover: #{$primary-blue-light};
  --tab: #{$black80};

  --text: #000;
  --text-darker: #000;
  --text-lighter: #686868;
  --text-lightest: #a5a5a5;
  --text-title: #{text-black};
  --text-primary: #{$primary-blue};
  --text-selected: #{$primary-blue};
  --text-secondary-button: #{$primary-blue};
  --text-secondary-button-disabled: #{$indigo20T};
  --header-text: #1f2023;
  --sign-graph: #000;

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.1);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.2);
}

// Dark mode themes
body.dark {
  --primary-foreground: #fff;
  --primary-background: #000;
  --background: #000;
  --background-lightgrey: #{$black80};
  --background-element: rgba(0, 0, 0, 0.56);
  --background-element-hover: rgba(0, 0, 0, 0.8);
  --background-main: #000 url("../assets/bg.svg") center/auto 60% no-repeat
    fixed;
  --background-logo: #ffffff;
  --secondary-button: #{$dark-purple};
  --emphasis-1: #{$indigo3}; // passive state
  --emphasis-2: #{$indigo2}; // active state
  --primary-accent: #{$primary};
  --outline: #fff;
  --option-hover: #{$primary-blue-dark};
  --outline-gray: #{$white30};
  --tab: #{$white};

  --text: #fff;
  --text-darker: #ffffff;
  --text-lighter: #bbbbbb;
  --text-lightest: #888888;
  --text-title: #ffffff;
  --text-primary: #{$primary};
  --text-selected: #{$primary};
  --text-secondary-button: #{$blue};
  --text-secondary-button-disabled: #{$blue40T};
  --header-text: #{$primary};
  --sign-graph: #{$primary-blue};

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);
}
