@import "../styles/theme.scss";

div.lpStakingBanner {
  width: 440px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--text-selected);

  p {
    color: var(--text);
    margin: 0;
    text-align: center;
  }

  a {
    text-decoration: underline;
    color: var(--text-selected);
  }
}
