@import "../styles/theme";
@import "../styles/viewports";

div.walletStatus {
  display: flex;
  justify-content: flex-end;
  & > button {
    color: var(--text-darker);
    border: none;
    padding: 0;
    background: none;
    font-family: $font-family;
    font-size: 16px;
    @include breakpoint(lg) {
      font-size: 11px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
  .hasAccount {
    display: flex;
    align-items: center;
    span.address {
      margin-right: 8px;
      @include breakpoint(md) {
        display: none;
      }
    }
  }
  .noAccount {
    height: 40px;
    width: 170px;
    border: 1px solid var(--outline);
    border-radius: 8px;
    line-height: 40px;
    color: var(--header-text);
    font-weight: $normal;

    @include breakpoint(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      line-height: 18px;
      width: 60px;
    }
    @include breakpoint(md) {
      position: relative;
      left: 20px;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      border: 2px solid var(--outline);
      transform: none;
      transition: none;
      cursor: pointer;
    }
  }
}
